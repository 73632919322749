import React from 'react'
import ArtboxoneCarousel from '../generic/ArtboxoneCarousel'
import useWindowDimensions from '../generic/useWindowDimensions'
import ProductPreview from '../product/Preview'

const LandingPageMotifListSlider = (options: any) => {
  const chunksDesktop = []
  const chunkSizeDesktop = 3

  for (let i = 0; i < options.display.length; i += chunkSizeDesktop) {
    chunksDesktop.push(options.display.slice(i, i + chunkSizeDesktop))
  }

  const chunksMobile = []
  const chunkSizeMobile = 2

  for (let i = 0; i < options.display.length; i += chunkSizeMobile) {
    chunksMobile.push(options.display.slice(i, i + chunkSizeMobile))
  }

  // on client side the window size will be checked and the slider is shown in 3 or 2 width layout
  const isDesktop = typeof window !== 'undefined' ? useWindowDimensions().width > 576 : false
  const chunks = isDesktop ? chunksDesktop : chunksMobile
  return (
    <div className="container">
      <div className="row">
        <div style={{ maxWidth: '100%', width:"100%"}}>
        <ArtboxoneCarousel showThumbs={false}>
          {chunks.map((display: any, index: number) => (
            <div key={`slider-chunk-${index}`} style={{ display: 'flex' }}>
              {display.map((p: any) => (
                <div className="col-6 col-sm-4" key={p.signature}>
                  <ProductPreview
                    material={p.material}
                    materials={p.materialData}
                    productData={p.productData}
                  />
                </div>
              ))}
            </div>
          ))}
        </ArtboxoneCarousel>
        </div>
      </div>
    </div>
  )
}

export default LandingPageMotifListSlider
